/* ALL COMMENTED CODE BLOCKS ARE FOR POST LAUNCH FEATURES - DO NOT DELETE */

import React, { useState, useEffect, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import RouteContent from "@src/services/content/routes"
import EmbeddedMap from "@src/components/map-embedded"
import RouteGuideList from "@src/components/content-route-guide-list"
import Button from "@src/components/core-button"
import Spinner from "@src/components/core-spinner"
//import MapActivityTiles from "@src/components/map-activity-tiles"
// import MapFilters from "@src/components/map-filters"
// import FilterDropdown from "@src/components/map-filter-dropdown"
// import Form from "@src/components/form"
// import InputCheckboxes from "@src/components/input-checkboxes"
// import InputSlider from "@src/components/input-slider"
// import MapSearch from "@src/components/map-search"
// import InputDropdown from "@src/components/input-dropdown"
// import InputText from "@src/components/input-text"
// import { MAP_FILTER_OPTIONS, FILTER_KEY_VALUES } from "@src/utils/constants/map-filters"

// const FiltersForm = styled(Form)`
//   @media only screen and (${props => props.theme.screen.small.min}) {
//     display: flex;
//     & h4:not(:first-child) {
//       border-top: 1px solid ${props => props.theme.darkBlue};
//       padding-top: 5px;
//       margin-top: 5px;
//     }
//   }

//   & FilterDropDown {
//     margin-right: 5px;
//   }

//   & h4:not(:first-child) {
//     border-top: 1px solid ${props => props.theme.darkBlue};
//     padding-top: 5px;
//     margin-top: 5px;
//   }
// `

// const StyledInputDropdown = styled(InputDropdown)`
//   div {
//     margin-top: 0px;
//   }
// `

// how many to keep in the component's cache of loaded routes
const MAP_ROUTES_LOADED_MAX = 200

// config for sidebar list
const MAP_ROUTES_LIST_PER_PAGE = 10

// config for specific route look up
const MAP_ROUTES_LOOKUP_ATTEMPT_PER_PAGE = 25
const MAP_ROUTES_LOOKUP_ATTEMPT_MAX = 10

const ContentMapStyle = styled.div`
  @media only screen and (${props => props.theme.screen.small.min}) {
    max-width: 1280px;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;

    display: flex;
    flex-direction: row-reverse;
  }
`

const RouteGuideListStyle = styled.div`
  width: 100%;
  & > .actions {
    text-align: center;
    margin-top: 10px;
  }
  a:hover {
    color: ${props => props.theme.black};
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-top: -5px;
    max-width: 380px;
    height: 800px;
    overflow-y: scroll;
    padding-bottom: 5px;
  }
`

const MapContainer = styled.div`
  width: 100%;

  @media only screen and (${props => props.theme.screen.small.min}) {
    padding-left: 16px;
    padding-right: 5px;
  }
`

const ContentMap = ({ coordinates, bounds }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [routeGuides, setRouteGuides] = useState([])
  const [focusedRoute, setFocusedRoute] = useState(null)
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false)
  // When the map sends visible route data, store it in a ref
  const visibleMapRoutesRef = useRef(null)
  // mirroring state in a ref allows checking value of focusedRoute without triggering dependency change
  const focusedRouteRef = useRef(focusedRoute)
  useEffect(() => {
    focusedRouteRef.current = focusedRoute
  }, [focusedRoute])

  // current state after the last lookup
  const lookup = useRef({
    params: {},
    page: 1,
  })

  // const [activeFilters, setActiveFilters] = useState({})

  // const handleFilters = useCallback(
  //   (value, key) => {
  //     setActiveFilters({ ...activeFilters, [key]: value })
  //   },
  //   [activeFilters, setActiveFilters]
  // )

  // local route guide cache
  // persists as long as the component is mounted
  const loadedRoutes = useRef([])
  const cacheRouteGuides = useCallback(routes => {
    for (const route of routes) {
      const routeCached = loadedRoutes.current.some(
        check => check.databaseId === route.databaseId
      )
      if (!routeCached) {
        loadedRoutes.current.push(route)
      }
    }
    // check if there's an overflow
    const overflow = loadedRoutes.length - MAP_ROUTES_LOADED_MAX
    if (overflow) {
      loadedRoutes.current = loadedRoutes.current.slice(overflow)
    }
  }, [])

  // specific routeId lookup
  const findRouteById = useCallback(
    (routeId, referencePoint, attempt) =>
      new Promise(resolve => {
        const cachedRoute = loadedRoutes.current.find(
          route => route.databaseId === routeId
        )
        if (cachedRoute) {
          resolve(cachedRoute)
          return
        }

        // not found locally, try from the API
        const lookupPage = attempt || 1
        if (lookupPage > MAP_ROUTES_LOOKUP_ATTEMPT_MAX) {
          resolve(null)
          return
        }
        RouteContent.getFilteredList(
          {
            gpsPoint: referencePoint || lookup.current.params.gpsPoint,
          },
          {
            page: lookupPage,
            perPage: MAP_ROUTES_LOOKUP_ATTEMPT_PER_PAGE,
          }
        ).then(result => {
          if (result.items) {
            // cache them
            cacheRouteGuides(result.items)

            // find the loadedRoute in the results
            const loadedRoute = result.items.find(
              route => route.databaseId === routeId
            )
            if (loadedRoute) {
              resolve(loadedRoute)
              return
            }

            // couldn't find it, make another attempt
            findRouteById(routeId, referencePoint, lookupPage + 1).then(resolve)
          }
        })
      }),
    [cacheRouteGuides]
  ) // careful of this one's dependencies since it is passed to map-embedded's getRouteById

  // route guide list loading
  const loadRouteRequests = useRef({
    lockQueue: [],
    responses: {},
  })
  const loadRoutes = useCallback(
    (params, page = 1) => {
      if (!params) {
        return
      }
      if (page === 1) {
        // page 1 always resets the list
        loadRouteRequests.current.lockQueue = []
        loadRouteRequests.current.responses = {}

        // only show the spinner when resetting the list
        setIsLoading(true)
      }

      // track the current lookup
      lookup.current.params = params
      lookup.current.page = page
      // use a unique lock to track this request in the queue
      const requestLock = Date.now()
      loadRouteRequests.current.lockQueue.push(requestLock)
      RouteContent.getFilteredList(
        params,
        {
          page,
          perPage: MAP_ROUTES_LIST_PER_PAGE,
        },
        requestLock
      )
        .then(({ requestKey, ...result }) => {
          if (result.items && result.items.length) {
            // no harm in caching the results
            cacheRouteGuides(result.items)
          }

          // keep the result for processing in its turn
          loadRouteRequests.current.responses[requestKey] = result

          // process any responses from the front of the queue
          while (
            loadRouteRequests.current.lockQueue.length &&
            loadRouteRequests.current.responses[
              loadRouteRequests.current.lockQueue[0]
            ]
          ) {
            // read the result and pop it off the queue
            const queuedResult =
              loadRouteRequests.current.responses[
                loadRouteRequests.current.lockQueue[0]
              ]
            delete loadRouteRequests.current.responses[
              loadRouteRequests.current.lockQueue[0]
            ]
            loadRouteRequests.current.lockQueue.shift()

            // handle the result
            const resultRoutes = queuedResult.items
            if (queuedResult.page === 1) {
              const truncateTo = MAP_ROUTES_LIST_PER_PAGE
              // If no truncation, still set truncated value to API response for cross-referencing in areas where dev and prod serv are not in sync.
              // E.G: Kamloops -- Map shows clusters, but API does not return any data -- current behavior is to show no routes found.
              const truncatedResultRoutes = truncateTo
                ? resultRoutes.slice(0, truncateTo)
                : resultRoutes
              // Check that visibleMapRoutes exist in truncated API response
              const crossReferencedRoutes =
                visibleMapRoutesRef.current &&
                truncatedResultRoutes.filter(truncatedRoute =>
                  visibleMapRoutesRef.current.includes(
                    truncatedRoute.databaseId
                  )
                )
              setRouteGuides(
                crossReferencedRoutes ? crossReferencedRoutes : resultRoutes
              )
            } else {
              setRouteGuides(routeGuides => routeGuides.concat(resultRoutes))
            }
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          setIsLoading(false)
          setIsLoadMoreLoading(false)
        })
    },
    [cacheRouteGuides]
  )
  const loadMoreRoutes = useCallback(() => {
    setIsLoadMoreLoading(true)
    loadRoutes(lookup.current.params, lookup.current.page + 1)
  }, [loadRoutes])

  const updateParamsDebounce = useRef({
    changes: {},
    timeout: null,
  })
  const updateParams = useCallback(
    changes => {
      if (updateParamsDebounce.current.timeout) {
        clearTimeout(updateParamsDebounce.current.timeout)
        updateParamsDebounce.current.timeout = null
      }
      updateParamsDebounce.current.changes = {
        ...updateParamsDebounce.current.changes,
        ...changes,
      }
      setIsLoading(true)
      updateParamsDebounce.current.timeout = setTimeout(() => {
        const currentChanges = updateParamsDebounce.current.changes
        updateParamsDebounce.current.changes = {}
        updateParamsDebounce.current.timeout = null

        // combine the debounced changes with the last lookup params
        // remove null/undefined entries (based on: https://stackoverflow.com/a/57625661/1798697)
        const loadParams = Object.entries({
          ...lookup.current.params,
          ...currentChanges,
        }).reduce(
          (result, [key, value]) =>
            value == null ? result : { ...result, [key]: value },
          {}
        )
        loadRoutes(loadParams, 1)
      }, 500)
    },
    [loadRoutes, setIsLoading]
  )

  // effects and callbacks

  /**
  // example using a value from the activeFilters state
  useEffect(() => {
    updateParams({ activities: [activeFilters.activity] })
  }, [activeFilters.activity])
  */

  const handleMapMoved = useCallback(
    (coordinates, visibleMapRoutes) => {
      visibleMapRoutesRef.current = visibleMapRoutes
      updateParams({ gpsPoint: { ...coordinates } }, visibleMapRoutes)
    },
    [updateParams]
  )

  const handleRouteFocus = useCallback(routeGuide => {
    if (
      (!routeGuide && focusedRouteRef.current) ||
      (routeGuide &&
        (!focusedRouteRef.current ||
          focusedRouteRef.current.databaseId !== routeGuide.databaseId))
    ) {
      setFocusedRoute(routeGuide)
    }
  }, [])

  return (
    <>
      {/* Commented Code Collapse -- Remove fragment pair below when incorporating filters */}
      <>
        {/* <MapSearch header="Search route guides by map">
        <InputText
          id="form-input-text-icon"
          name="form-input-text-icon"
          placeholder="Region"
          label="Text Input with Icon"
          description="This is a demo InputText with an icon"
          icon="search"
          onChange={event =>
            handleFilters(event.target.value, FILTER_KEY_VALUES.search)
          }
        />
        <StyledInputDropdown
          id="base-dropdown"
          name="base-dropdown"
          placeholder="Activity"
          options={MAP_FILTER_OPTIONS.activitites}
          onChange={val => handleFilters(val.value, FILTER_KEY_VALUES.activity)}
        />
      </MapSearch>
      <MapFilters header="Filters (optional)">
        <FiltersForm generateDataLayer={false}>
          <FilterDropdown label="Difficulty">
            <InputCheckboxes
              variant="blue"
              id="base-checkboxes"
              name="base-checkboxes"
              items={MAP_FILTER_OPTIONS.difficulty}
              onChange={val => handleFilters(val, FILTER_KEY_VALUES.difficulty)}
            />
          </FilterDropdown>
          <FilterDropdown label="Distance" widthType="slider">
            <InputSlider
              id="base-slider-range"
              name="base-slider-range"
              label="Base Slider Range"
              min={0}
              max={100}
              value={[10, 25]}
              range
              onChange={val => handleFilters(val, FILTER_KEY_VALUES.distance)}
            />
          </FilterDropdown>

          <FilterDropdown label="Rating" widthType="slider">
            <InputSlider
              id="base-slider-range"
              name="base-slider-range"
              label="Base Slider Range"
              min={0}
              max={10}
              value={[0, 10]}
              range
              onChange={val => handleFilters(val, FILTER_KEY_VALUES.rating)}
            />
          </FilterDropdown>

          <FilterDropdown label="Elevation" widthType="slider">
            <InputSlider
              id="base-slider-range"
              name="base-slider-range"
              label="Base Slider Range"
              min={-100}
              max={100}
              value={[-25, 25]}
              range
              onChange={val => handleFilters(val, FILTER_KEY_VALUES.elevation)}
            />
          </FilterDropdown>

          <FilterDropdown label="More Filters" hideOnMobile>
            <h4>Time of Year</h4>
            <InputCheckboxes
              variant="blue"
              id="base-checkboxes-time-of-year"
              name="base-checkboxes-time-of-year"
              items={MAP_FILTER_OPTIONS.timeOfYear}
              onChange={val => handleFilters(val, FILTER_KEY_VALUES.timeOfYear)}
            />
            <h4>Backcountry Campsites</h4>
            <InputCheckboxes
              variant="blue"
              id="base-checkboxes-campsites"
              name="base-checkboxes-campsites"
              items={MAP_FILTER_OPTIONS.campsites}
              onChange={val => handleFilters(val, FILTER_KEY_VALUES.campsites)}
            />
            <h4>Toilets</h4>
            <InputCheckboxes
              variant="blue"
              id="base-checkboxes-toilets"
              name="base-checkboxes-toilets"
              items={MAP_FILTER_OPTIONS.toilets}
              onChange={val => handleFilters(val, FILTER_KEY_VALUES.toilets)}
            />
            <h4>Family Friendly</h4>
            <InputCheckboxes
              variant="blue"
              id="base-checkboxes-family-friendly"
              name="base-checkboxes-family-friendly"
              items={MAP_FILTER_OPTIONS.familyFriendly}
              onChange={val =>
                handleFilters(val, FILTER_KEY_VALUES.familyFriendly)
              }
            />
            <h4>Crowd Levels</h4>
            <InputCheckboxes
              variant="blue"
              id="base-checkboxes-crowd-levels"
              name="base-checkboxes-crowd-levels"
              items={MAP_FILTER_OPTIONS.crowdLevels}
              onChange={val =>
                handleFilters(val, FILTER_KEY_VALUES.crowdLevels)
              }
            />
          </FilterDropdown>
        </FiltersForm>
      </MapFilters> */}
      </>

      <ContentMapStyle>
        <MapContainer>
          <EmbeddedMap
            centerCoordinates={coordinates}
            focusBounds={bounds}
            focusedRoute={focusedRoute}
            getRouteById={findRouteById}
            onMapMove={handleMapMoved}
            onRouteFocus={handleRouteFocus}
          />
        </MapContainer>
        <RouteGuideListStyle>
          <RouteGuideList
            variant="map"
            button
            routeGuides={routeGuides}
            isLoading={isLoading}
            onRouteGuideHover={handleRouteFocus}
            focusedRoute={focusedRoute}
            actions={
              <Button
                key="map-route-guides-load-more"
                onClick={loadMoreRoutes}
                color="outline"
                size="small"
              >
                {isLoadMoreLoading ? <Spinner size="sm" /> : "Load More"}
              </Button>
            }
          />
        </RouteGuideListStyle>
      </ContentMapStyle>
    </>
  )
}

ContentMap.propTypes = {
  routeGuideList: PropTypes.bool,
  coordinates: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  bounds: PropTypes.shape({
    sw: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    ne: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  }),
}

export default ContentMap
