import React from "react"
import styled from "styled-components"
import { usePopperTooltip } from "react-popper-tooltip"
import PropTypes from "prop-types"
import "react-popper-tooltip/dist/styles.css"
import HtmlContent from "@src/components/core-value-html"

const TriggerButton = styled.button`
  cursor: pointer;
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
`
const TooltipWrapper = styled.div`
  display: flex;
  & .tooltip-container {
    z-index: ${props => props.theme.layers.activeUI + 50};
    max-width: 100vw;
    width: 400px;
    padding: 15px;
    & .title {
      text-align: center;
      font-weight: 500;
      margin-bottom: 0.5em;
    }
    & .details {
    }
    &:not(.open) {
      display: none;
    }
  }

  & .tooltip-trigger {
    &:hover,
    &:focus {
      color: ${props => props.theme.primary};
    }
  }
`

const Tooltip = ({
  className,
  triggerElement,
  triggerProps,
  children,
  tooltipHtml,
}) => {
  const popperOptions = { placement: "top" }
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(
    { trigger: "click", interactive: true, delayHide: 200 },
    popperOptions
  )

  return (
    <TooltipWrapper className={className}>
      <TriggerButton
        type="button"
        className="tooltip-trigger"
        ref={setTriggerRef}
        {...triggerProps}
      >
        {triggerElement}
      </TriggerButton>
      {
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container" + (visible ? " open" : ""),
          })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {tooltipHtml && <HtmlContent html={tooltipHtml} />}
          {children}
        </div>
      }
    </TooltipWrapper>
  )
}
Tooltip.propTypes = {
  className: PropTypes.string,
  triggerElement: PropTypes.node.isRequired,
  triggerProps: PropTypes.object,
  tooltipHtml: PropTypes.string,
  children: PropTypes.node,
}
Tooltip.defaultProps = {
  triggerProps: {},
}
export default Tooltip
