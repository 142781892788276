import React, { useEffect, useRef, useCallback } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import PropTypes from "prop-types"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import "../styles/map.css"
import theme from "@src/styles/theme"
import { document, exists } from "browser-monads"
import RouteGuideDetailTile from "@src/components/content-route-guide-detail-tile"
import { ThemeProvider } from "styled-components"

// import { createFilterArray } from "@src/utils/filtering"

const LAYERS = {
  ROUTES: "10a-routes",
  ROUTE_CLUSTERS: "route-clusters",
  ROUTE_CLUSTER_COUNTS: "route-cluster-counts",
  ROUTE_SINGLE_CLUSTERS: "route-single-clusters",
  ROUTE_SINGLE_CLUSTER_COUNTS: "route-single-cluster-counts",
}
const SOURCES = {
  ROUTES_GEOJSON: "routes-geojson",
}
const cursorLayers = [
  LAYERS.ROUTES,
  LAYERS.ROUTE_CLUSTERS,
  LAYERS.ROUTE_SINGLE_CLUSTERS,
]
const routeLayers = [LAYERS.ROUTES, LAYERS.ROUTE_SINGLE_CLUSTERS]
const clusterRadius = 80.0

const EmbeddedMapStyled = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  background: grey;
  margin-bottom: 26px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 794px;
    margin-bottom: 0px;
  }
`

const getUniqueFeatures = (array, comparatorProperty) =>
  array.filter(
    (v, i, a) =>
      a.findIndex(
        t =>
          t.properties[comparatorProperty] === v.properties[comparatorProperty]
      ) === i
  )

const getFeatures = (map, options, uniqueProperty) => {
  const features = map.queryRenderedFeatures(options)
  if (features) {
    return getUniqueFeatures(features, uniqueProperty)
  }
}

const findRoutesInView = map => {
  const routeIDs = []
  const higherLevelClusterFeatures = getFeatures(
    map,
    { layers: [LAYERS.ROUTE_CLUSTERS] },
    "cluster_id"
  )
  const singleClusterFeatures = getFeatures(
    map,
    { layers: [LAYERS.ROUTE_SINGLE_CLUSTERS] },
    "databaseId"
  )
  higherLevelClusterFeatures.forEach(feature =>
    feature.properties.routes
      .split(",")
      .forEach(route => route && routeIDs.push(Number(route)))
  )
  singleClusterFeatures.forEach(feature =>
    routeIDs.push(Number(feature.properties.databaseId))
  )
  return routeIDs
}
// Ref: How to use a React component as a popup
// https://www.lostcreekdesigns.co/writing/how-to-create-a-map-popup-component-using-mapbox-and-react/
// use ReactDOM.render to display popup content in a DOM element managed by Mapbox
const openRoutePopup = (map, route, { onClose }) => {
  if (exists(document) && map) {
    const popup = new mapboxgl.Popup({ focusAfterOpen: false })
    const popupNode = document.createElement("div")

    const startPoint = route.start

    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <RouteGuideDetailTile variant="popup" routeGuide={route} />
      </ThemeProvider>,
      popupNode
    )

    const routeLines = map.queryRenderedFeatures({
      layers: [LAYERS.ROUTES],
      filter: ["==", ["get", "databaseId"], route.databaseId],
    })
    for (const routeLine of routeLines) {
      map.setFeatureState(routeLine, { focus: true })
    }
    popup.on("close", event => {
      for (const routeLine of routeLines) {
        map.setFeatureState(routeLine, { focus: false })
      }
      if (onClose) {
        onClose(event)
      }
    })

    popup.setLngLat(startPoint).setDOMContent(popupNode).addTo(map)
    return popup
  }
}

const EmbeddedMap = ({
  centerCoordinates,
  focusBounds,
  focusedRoute,
  getRouteById,
  onMapMove,
  onRouteFocus,
}) => {
  const mapContainerRef = useRef(null)
  const map = useRef(null)
  const popup = useRef(null)

  const triggerRouteFocus = useCallback(
    route => {
      if (onRouteFocus) {
        onRouteFocus(route)
      }
    },
    [onRouteFocus]
  )

  const handlePopupClose = useCallback(
    event => {
      if (event.target !== popup.current) {
        return
      }
      triggerRouteFocus(null)
      if (popup.current) {
        popup.current = null
      }
    },
    [triggerRouteFocus]
  )

  useEffect(() => {
    if (map.current) return

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: process.env.GATSBY_MAPBOX_TOKEN,
      style: process.env.GATSBY_MAPBOX_STYLE_URL,
      zoom: 6,
    })

    map.current.addControl(new mapboxgl.FullscreenControl(), "top-right")
    map.current.addControl(new mapboxgl.GeolocateControl(), "top-right")
    map.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      }),
      "top-right"
    )

    map.current.on("load", () => {
      map.current.setLayerZoomRange(LAYERS.ROUTES, 11, 24)
      map.current.setPaintProperty(LAYERS.ROUTES, "line-width", [
        "case",
        ["boolean", ["feature-state", "focus"], false],
        3,
        1.5,
      ])

      const updateMapCanvas = style => {
        for (const prop in style) {
          map.current.getCanvas().style[prop] = style[prop]
        }
      }

      // CLUSTER CODE
      map.current.addSource(SOURCES.ROUTES_GEOJSON, {
        type: "geojson",
        data: process.env.GATSBY_MAPBOX_GEOJSON_ROUTES,
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: clusterRadius,
        clusterProperties: {
          routes: ["concat", ["concat", ["get", "databaseId"], ","]],
        },
      })

      const clusterSource = map.current.getSource(SOURCES.ROUTES_GEOJSON)

      // add the cluster circle layer (2+)
      map.current.addLayer({
        id: LAYERS.ROUTE_CLUSTERS,
        type: "circle",
        source: SOURCES.ROUTES_GEOJSON,
        filter: ["has", "point_count"],
        paint: {
          "circle-color": theme.primary,
          "circle-stroke-color": theme.white,
          "circle-stroke-width": 2,
          "circle-opacity": 0.67,

          // generate different sized circles based on point_count
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      })

      // For clusters that only have a single point (i.e. 1)
      map.current.addLayer({
        id: LAYERS.ROUTE_SINGLE_CLUSTERS,
        type: "circle",
        source: SOURCES.ROUTES_GEOJSON,
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": theme.primary,
          "circle-stroke-color": theme.white,
          "circle-radius": 20,
          "circle-stroke-width": 2,
          "circle-opacity": 0.67,
        },
      })

      // add the cluster labels (2+)
      map.current.addLayer({
        id: LAYERS.ROUTE_CLUSTER_COUNTS,
        type: "symbol",
        source: SOURCES.ROUTES_GEOJSON,
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      })

      // For clusters that only have a single point (i.e. 1)
      map.current.addLayer({
        id: LAYERS.ROUTE_SINGLE_CLUSTER_COUNTS,
        type: "symbol",
        source: SOURCES.ROUTES_GEOJSON,
        filter: ["!", ["has", "point_count"]],
        layout: {
          "text-field": "1",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      })

      map.current.on("moveend", () => {
        const { lat, lng: lon } = map.current.getCenter()
        if (onMapMove) {
          onMapMove({ lat, lon }, findRoutesInView(map.current))
        }
      })
      // inspect a cluster on click
      map.current.on("click", LAYERS.ROUTE_CLUSTERS, event => {
        const features = map.current.queryRenderedFeatures(event.point, {
          layers: [LAYERS.ROUTE_CLUSTERS],
        })
        const clusterId = features[0].properties.cluster_id
        console.log("CLUSTER CLICK =>", features)

        clusterSource.getClusterLeaves(
          clusterId,
          10,
          0,
          function (error, children) {
            console.log(
              "CLUSTER CHILDREN for #" + clusterId + " => ",
              error,
              children
            )
          }
        )

        map.current
          .getSource(SOURCES.ROUTES_GEOJSON)
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return
            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            })
          })
      })

      // mouse interactions for routeLayers
      for (const routeLayer of routeLayers) {
        map.current.on("click", routeLayer, event => {
          const eventCoords = event.lngLat
            ? { lat: event.lngLat.lat, lon: event.lngLat.lng }
            : null
          const feature = event.features[0]
          getRouteById(feature.properties.databaseId, eventCoords).then(
            route => {
              triggerRouteFocus(route)
              if (route && route.start) {
                map.current.flyTo({
                  center: route.start,
                  zoom: 11,
                })
              }
            }
          )
        })
        map.current.on("mouseenter", routeLayer, event => {
          const eventCoords = event.lngLat
            ? { lat: event.lngLat.lat, lon: event.lngLat.lng }
            : null
          const feature = event.features[0]
          getRouteById(feature.properties.databaseId, eventCoords).then(
            route => {
              triggerRouteFocus(route)
            }
          )
        })
        map.current.on("mouseleave", routeLayer, event => {
          const eventCoords = event.lngLat
            ? { lat: event.lngLat.lat, lon: event.lngLat.lng }
            : null
          const hoveredFeatures = map.current.queryRenderedFeatures(
            event.point,
            {
              layers: routeLayers,
            }
          )
          if (!hoveredFeatures.length) {
            triggerRouteFocus(null)
          } else {
            const feature = hoveredFeatures[0]
            getRouteById(feature.properties.databaseId, eventCoords).then(
              route => {
                triggerRouteFocus(route)
              }
            )
          }
        })
      }

      // mouse interactions for cursorLayers
      for (const cursorLayer of cursorLayers) {
        map.current.on("mouseenter", cursorLayer, () => {
          updateMapCanvas({ cursor: "pointer" })
        })
        map.current.on("mouseleave", cursorLayer, event => {
          const hoveredFeatures = map.current.queryRenderedFeatures(
            event.point,
            {
              layers: cursorLayers,
            }
          )
          if (!hoveredFeatures.length) {
            updateMapCanvas({ cursor: "" })
          }
        })
      }

      map.current.once("idle", () => {
        // finished loading
        const { lat, lng: lon } = map.current.getCenter()
        if (onMapMove) {
          onMapMove({ lat, lon }, findRoutesInView(map.current))
        }
      })
    })

    return () => {
      map.current.remove()
    }
  }, [getRouteById, triggerRouteFocus, handlePopupClose, onMapMove])
  // careful this effect's dependencies don't change in component lifecycle
  // for prop dependencies, careful they don't change in parent's lifecycle

  useEffect(() => {
    if (!map.current) {
      return
    }
    if (focusBounds && focusBounds.sw && focusBounds.ne) {
      const options = {}
      if (centerCoordinates) {
        options.center = centerCoordinates
      }
      map.current.fitBounds([focusBounds.sw, focusBounds.ne], options)
    } else if (centerCoordinates) {
      map.current.setCenter(centerCoordinates)
    }
    triggerRouteFocus(null)
  }, [focusBounds, centerCoordinates, triggerRouteFocus])

  useEffect(() => {
    if (!map.current) {
      return
    }

    // disconnect from popup.current before calling remove
    // this prevents the close handler from calling triggerRouteFocus(null)
    const closePopup = popup.current
    popup.current = null
    if (closePopup) {
      closePopup.remove()
    }

    if (focusedRoute) {
      popup.current = openRoutePopup(map.current, focusedRoute, {
        onClose: handlePopupClose,
      })
    }
  }, [focusedRoute, handlePopupClose])

  return <EmbeddedMapStyled ref={mapContainerRef} />
}

EmbeddedMap.propTypes = {
  centerCoordinates: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  focusBounds: PropTypes.shape({
    sw: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    ne: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  }),
  focusedRoute: PropTypes.object,
  getRouteById: PropTypes.func, // must return a promise
  onMapMove: PropTypes.func,
  onRouteFocus: PropTypes.func,
}

export default EmbeddedMap
