import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "@src/components/core-button"
import RouteGuideTile, {
  RouteGuideDetailTilePropTypes,
} from "@src/components/content-route-guide-detail-tile"
import Spinner from "@src/components/core-spinner"

const Title = styled.h2`
  padding: 20px 16px 10px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 40px 80px 20px;
    text-align: center;
  }
`
const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  & > :not(:first-child) {
    margin-top: 10px;
  }
`

const StyledSpinner = styled(Spinner)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  align-items: center;
`

const RouteGuideList = ({
  title,
  actions,
  viewMore,
  variant,
  routeGuides,
  countOffset,
  isLoading,
  lazyloadImages,
  onRouteGuideHover,
  focusedRoute,
}) => {
  const actionList = []
  if (viewMore) {
    actionList.push(
      <Button
        key={`view-more-${viewMore}`}
        to={viewMore}
        aria-label="view more route guides"
      >
        View more
      </Button>
    )
  }
  if (actions) {
    actionList.push(actions)
  }

  const RouteGuideTiles =
    routeGuides &&
    routeGuides.length &&
    routeGuides.reduce((result, routeGuide, arrayIndex) => {
      const index = arrayIndex + countOffset
      if (routeGuide && routeGuide.name) {
        result.push(
          <RouteGuideTile
            key={`${index}-${routeGuide.name}`}
            variant={variant}
            routeGuide={routeGuide}
            focused={
              focusedRoute && focusedRoute.databaseId === routeGuide.databaseId
            }
            onHover={onRouteGuideHover}
            lazyloadImages={lazyloadImages}
          />
        )
      }
      return result
    }, [])

  return (
    <>
      {title && <Title>{`${title}`}</Title>}
      {isLoading ? (
        <StyledSpinner />
      ) : RouteGuideTiles && RouteGuideTiles.length ? (
        <ListWrapper>{RouteGuideTiles}</ListWrapper>
      ) : (
        <h3>No routes found</h3>
      )}
      {actionList.length && !isLoading ? (
        <div className="actions">{actionList}</div>
      ) : undefined}
    </>
  )
}
RouteGuideList.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.node,
  viewMore: PropTypes.string,
  variant: PropTypes.oneOf(["map", "popup"]),
  routeGuides: PropTypes.arrayOf(
    PropTypes.shape({ ...RouteGuideDetailTilePropTypes })
  ),
  countOffset: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  lazyloadImages: PropTypes.bool,
  onRouteGuideHover: PropTypes.func,
  focusedRoute: PropTypes.object,
}
RouteGuideList.defaultProps = {
  countOffset: 0,
  lazyloadImages: true,
}
export default RouteGuideList
