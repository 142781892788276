import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { window, exists } from "browser-monads"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import ResponsiveSection from "@src/components/container-responsive-section"
import InteractiveMap from "@src/components/map-interactive"
import RegionTiles from "@src/components/content-region-tiles"
import TourTypeTiles from "@src/components/content-tour-type-tiles"
import Button from "@src/components/core-button"
import { ImagePropTypes } from "@src/components/core-image"
import PageHelmet from "@src/components/page-helmet"
import { useRouteRegions } from "@src/hooks/useRouteRegions"
import { orderByField } from "@src/utils/sorting"
import { BASE_PATH_TOURS } from "@src/utils/constants"
import { animateScroll as scroll } from "react-scroll"
import { SITE_DOMAIN, BASE_PATH_MAP } from "@src/utils/constants"

const MapTitle = styled.h1`
  text-align: center;
  padding-top: 10px;
`

const MAP_SEO_TITLE = "Route Map: Plan Your Hiking and Backpacking Trails"
const MAP_SEO_META = {
  description:
    "Use the route map to plan your outdoor adventures. Explore with confidence using our trusted, guidebook quality information for thousands of curated trails.",
  canonical: `${SITE_DOMAIN}${BASE_PATH_MAP}`,
  robots: ["follow", "index"],
}
const DEFAULT_MAP_CENTER = {
  lat: 51.044733,
  lon: -114.071883,
}
const Map = ({ location, params, data, pageContext }) => {
  const [regionTitle, setRegionTitle] = useState(null)
  const {
    tenTours: {
      tourRegions: { nodes: tourRegions },
      tourCategories: { nodes: tourCategories },
    },
  } = data

  const { tourCategories: tourCategoriesOrder, tourRegions: tourRegionsOrder } =
    pageContext

  const routeRegions = useRouteRegions()

  const [coordinates, setCoordinates] = useState(DEFAULT_MAP_CENTER)
  const [bounds, setBounds] = useState(null)

  useEffect(() => {
    scroll.scrollToTop()
  }, [])
  useEffect(() => {
    let foundBounds = false
    let defaultCoordinates
    if (
      !location.hash &&
      params &&
      params["*"] &&
      routeRegions &&
      routeRegions.length
    ) {
      const loadRegion = routeRegions.find(check => check.slug === params["*"])
      loadRegion && setRegionTitle(loadRegion.name)
      if (
        loadRegion &&
        loadRegion.gpsBounds &&
        loadRegion.gpsBounds.sw &&
        loadRegion.gpsBounds.ne
      ) {
        setBounds(loadRegion.gpsBounds)
        defaultCoordinates = {
          lat: loadRegion.gps.lat,
          lon: loadRegion.gps.lon,
        }
        foundBounds = true
      }
    }

    if (location && location.hash) {
      const loadRegion = routeRegions.find(check => check.slug === params["*"])
      loadRegion && setRegionTitle(loadRegion.name)
      // drop the leading '#' and parse the rest
      const parseHash = location.hash.substr(1).split(",")
      if (
        parseHash.length > 1 &&
        !isNaN(parseHash[0]) &&
        !isNaN(parseHash[1])
      ) {
        defaultCoordinates = {
          lat: Number(parseHash[0]),
          lon: Number(parseHash[1]),
        }
        if (parseHash.length > 5) {
          setBounds({
            ne: { lat: Number(parseHash[2]), lon: Number(parseHash[3]) },
            sw: { lat: Number(parseHash[4]), lon: Number(parseHash[5]) },
          })
        }
      }
    }
    if (!defaultCoordinates) {
      // TODO: can we get it from locale some how?
    }
    if (defaultCoordinates) {
      setCoordinates(defaultCoordinates)
    } else if (!foundBounds && exists(window)) {
      window.navigator.geolocation.getCurrentPosition(position => {
        if (position && position.coords) {
          setCoordinates({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          })
        }
      })
    }
  }, [location, params, routeRegions, regionTitle])
  GTM.dataLayerPushPageInfo({
    template: "map-page",
  })
  return (
    <PrimaryLayout>
      <PageHelmet title={MAP_SEO_TITLE} meta={MAP_SEO_META} />
      <MapTitle>
        {regionTitle ? `${regionTitle} Trail Map` : "Find Hikes Near Me"}
      </MapTitle>
      <InteractiveMap
        routeGuideList
        coordinates={coordinates}
        bounds={bounds}
      />
      <ResponsiveSection variant="tiles">
        <h2>Experience Tours in These Great Destinations</h2>
        <RegionTiles
          content={tourRegions
            ?.sort(orderByField("slug", tourRegionsOrder))
            .map(tourRegion => ({
              title: tourRegion.title,
              link: tourRegion.uri,
              image: tourRegion.images?.featureTile,
            }))}
          actions={[
            <Button to={BASE_PATH_TOURS} key={`view-tours`}>
              View Tours
            </Button>,
          ]}
        />
        <h2>Explore Tours by Activity</h2>
        <TourTypeTiles
          content={tourCategories
            ?.sort(orderByField("slug", tourCategoriesOrder))
            .map(tourCategory => ({
              title: `${tourCategory.title} Tours`,
              link: tourCategory.uri.replace("/activity/", "/tours/"),
              image: tourCategory.images?.featureTile,
            }))}
        />
      </ResponsiveSection>
    </PrimaryLayout>
  )
}

Map.propTypes = {
  location: PropTypes.object,
  params: PropTypes.object,
  data: PropTypes.shape({
    tenTours: PropTypes.shape({
      tourRegions: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            uri: PropTypes.string,
            images: PropTypes.shape({
              featureTile: PropTypes.shape({ ...ImagePropTypes }),
            }),
          })
        ),
      }),
      tourCategories: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            uri: PropTypes.string,
            images: PropTypes.shape({
              featureTile: PropTypes.shape({ ...ImagePropTypes }),
            }),
          })
        ),
      }),
    }),
  }),
  pageContext: PropTypes.shape({
    tourCategories: PropTypes.arrayOf(PropTypes.string),
    tourRegions: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default Map

export const mapPageQuery = graphql`
  query ($tourCategories: [String], $tourRegions: [String]) {
    tenTours {
      tourRegions(where: { slug: $tourRegions }) {
        nodes {
          uri
          slug
          title: name
          images {
            featureTile {
              ...TenToursGQL_ImageSizesQuery
            }
          }
        }
      }
      tourCategories(where: { slug: $tourCategories }) {
        nodes {
          uri
          slug
          title: name
          images {
            featureTile {
              ...TenToursGQL_ImageSizesQuery
            }
          }
        }
      }
    }
  }
`
